import React, { useEffect } from "react";

import PureCounter from "@srexi/purecounterjs";
import AOS from "aos";
import "aos/dist/aos.css";

import MainLayout from "../common/ui/layout/main-layout";
import Hero from "./hero";
import About from "./About";
import Suppliers from "./Suppliers";
import Markets from "./markets";
import Contact from "./Contact";
import Services from "./services";
import Innovations from "./Innovations";
import ScrollToTop from "react-scroll-to-top";

const App = () => {
  useEffect(() => {
    // initialize AOS library
    AOS.init({ once: true });

    // inittialize Purecounter library
    new PureCounter();
  }, []);

  return (
    <MainLayout>
      <Hero />
      <About />
      <Markets bg />
      <Innovations />
      <Services bg />
      <Suppliers />
      <Contact bg />
      <ScrollToTop
        smooth
        top={700}
        svgPath="M24.94,67.88A14.66,14.66,0,0,1,4.38,47L47.83,4.21a14.66,14.66,0,0,1,20.56,0L111,46.15A14.66,14.66,0,0,1,90.46,67.06l-18-17.69-.29,59.17c-.1,19.28-29.42,19-29.33-.25L43.14,50,24.94,67.88Z"
        viewBox="0 0 115.4 122.88"
        className="scroll-top-button"
      />
    </MainLayout>
  );
};

export default App;
