import asutex from "./asutex.png";
import cj from "./cj.png";
import elkem from "./elkem.png";
import nanoflam from "./nanoflam.png";
import rudolf from "./rudolf.png";
import solvay from "./solvay.png";
import yorkshire from "./yorkshire.png";

const clientImgs = { asutex, cj, elkem, nanoflam, rudolf, solvay, yorkshire };

export default clientImgs;
