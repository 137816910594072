import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
  CompoundSamba1,
  CompoundSamba2,
} from "../../../common/ui/assets/images/innovations";

import styles from "../Innovations.module.scss";

const CompoundSamba = () => {
  return (
    <Container
      className={`${styles.container} px-3 px-sm-0`}
      data-aos="fade-up"
      id="Compound-Samba-PES"
    >
      <Row className="mx-0 pt-4">
        <Col className={`${styles.title__info} d-inline-block`}>
          <h1 className={styles.title}>Compound Samba PES</h1>
        </Col>
      </Row>
      <Row className={styles.compoundSamba}>
        <Col lg={6}>
          <Image src={CompoundSamba1} alt="" fluid />
          <p>
            A process that uses the exact amount of dyes, chemicals, and water
            that garments need, resulting in zero wastewater coming out of the
            dyeing process. It’s used to get white seams effect on both cotton
            and polyester garments.
          </p>
        </Col>
        <Col lg={6}>
          <Image src={CompoundSamba2} alt="" fluid />
          <p>
            The resulting fastness is improved, and the garments treated
            maintain original hydrophilicity for better moisture management. For
            exhaustion application, we provide our certified steady and reliable
            range: ASUPRINT ECO BS.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default CompoundSamba;
