import React, { FC } from "react";

import { Container } from "react-bootstrap";
import Section from "../../common/ui/base/section";
import SectionTitle from "../../common/ui/layout/content-layout/SectionTitle";
import { Box, Typography } from "@mui/material";
import CTabContext from "../../common/ui/base/CTabContext";
import { ServiceTabItem } from "../model";
import { BREAK_POINTS_MASONRY } from "../../common/utils/constants";
import Masonry from "react-masonry-css";

import styles from "./Services.module.scss";
import { warehouse } from "../../common/ui/assets/images/services/warehouse";
import { blending } from "../../common/ui/assets/images/services/facility";
import { lab } from "../../common/ui/assets/images/services/lab";

interface Props {
  bg?: boolean;
}

const Services: FC<Props> = (props: Props) => {
  const { bg } = props;

  const services = [
    {
      label: "Warehouse & logistic",
      data: warehouse.map((item, idx) => {
        return { img: item, alt: `wh${idx + 1}` };
      }),
    },
    {
      label: "Blending Facility",
      data: blending.map((item, idx) => {
        return { img: item, alt: `wh${idx + 1}` };
      }),
    },
    {
      label: "Laboratory",
      data: lab.map((item, idx) => {
        return { img: item, alt: `wh${idx + 1}` };
      }),
    },
  ];

  const renderMasonry = (
    data: Array<{ img: string; alt: string }>,
    label: string
  ) => {
    return (
      <Box sx={{ width: "100%" }}>
        <Typography className="text-center mb-4 fs-1 fw-bold text-capitalize">
          {label}
        </Typography>
        <Masonry
          breakpointCols={BREAK_POINTS_MASONRY}
          className={`${styles.masonry} d-flex gap-3`}
        >
          {data.map((item, idx) => (
            <img
              src={`${item.img}`}
              srcSet={`${item.img}`}
              alt={item.alt}
              loading={idx > 2 ? "lazy" : "eager"}
              className="mb-2"
              key={item.img}
            />
          ))}
        </Masonry>
      </Box>
    );
  };

  const tabs: Array<ServiceTabItem> = services.map(({ data, label }) => {
    return { label, component: renderMasonry(data, label) };
  });

  return (
    <Section id="services" className={styles.services} bg={bg}>
      <Container data-aos="fade-up">
        <SectionTitle
          title="services"
          subTitle="Check our"
          section="services"
          desc=""
        />

        <Box
          sx={{
            width: "100%",
          }}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <CTabContext tabs={tabs} fluid />
        </Box>
      </Container>
    </Section>
  );
};

export default Services;
