import React, { FC } from "react";

import styles from "./SectionTitle.module.scss";

interface Props {
  title: string;
  subTitle: string;
  section: string;
  desc: string;
}

const SectionTitle: FC<Props> = (props: Props) => {
  const { title, subTitle, section, desc } = props;
  return (
    <div className={styles["section-title"]}>
      <h2>{title}</h2>
      <h3>
        {subTitle} <span>{section}</span>
      </h3>
      <p>{desc}</p>
    </div>
  );
};

export default SectionTitle;
