export const scrollToID = (id: string) => {
  const section = (document.querySelector(id) as HTMLElement) || null;
  const header = (document.querySelector("#header") as HTMLElement) || null;

  let offset = 0;

  if (id === "#hero") offset = -40;

  if (section && header) {
    window.scrollTo({
      top: section.offsetTop - header.offsetHeight + offset,
      behavior: "smooth",
    });
  }
};
