import React, { FC, useState } from "react";
import { scrollToID } from "../../../utils/helpers/scrollToID";
import { NavItem } from "../../layout/main-layout/model";

import styles from "./CAccordion.module.scss";

interface Props {
  item: NavItem;
  CStyle?: string;
  hideModalMenu: () => void;
}

const CAccordion: FC<Props> = (props: Props) => {
  const { CStyle, item, hideModalMenu } = props;
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);

  return (
    <div
      className={`${styles.accordion} ${CStyle}`}
      onClick={() => setIsShowDropdown(!isShowDropdown)}
    >
      <div className={styles.accordionToggle}>
        <span>{item.title}</span>
        <i className="fa-solid fa-chevron-down"></i>
      </div>
      <ul className={`${styles.panel} ${isShowDropdown ? styles.show : ""}`}>
        {item.subItems &&
          item.subItems.map((subItem, i) => (
            <li
              key={i}
              className={styles.subItem}
              onClick={(e) => {
                e.preventDefault();
                if (subItem.href) {
                  scrollToID(subItem.href);
                  hideModalMenu();
                }
              }}
            >
              <a href={subItem.href}>{subItem.title}</a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CAccordion;
