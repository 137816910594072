import React, { FC, ReactNode, useRef, useState } from "react";
import Modal from "../../base/modal";
import Footer from "./footer";
import Header from "./header";
import { NavItem } from "./model";
import TopBar from "./top-bar";

import styles from "./MainLayout.module.scss";
import { INNOVATIONS, MARKETS } from "./constants";

interface Props {
  children: ReactNode;
}

const MainLayout: FC<Props> = (props: Props) => {
  const { children } = props;

  const [navItems, setNavItems] = useState<Array<NavItem>>([
    {
      title: "home",
      active: true,
      href: "#hero",
      ref: useRef<HTMLLIElement>(null),
    },
    {
      title: "about",
      active: false,
      href: "#about",
      ref: useRef<HTMLLIElement>(null),
    },
    {
      title: "Our Markets",
      active: false,
      href: "",
      subItems: MARKETS.map((item) => {
        return {
          title: item,
          active: false,
          href: "#markets",
        };
      }),
      ref: useRef<HTMLLIElement>(null),
    },
    {
      title: "innovations",
      active: false,
      href: "",
      subItems: INNOVATIONS.map((item) => {
        return {
          title: item,
          active: false,
          href: `#${item.replaceAll(" ", "-")}`,
        };
      }),
      ref: useRef<HTMLLIElement>(null),
    },
    {
      title: "services",
      active: false,
      href: "#services",
      ref: useRef<HTMLLIElement>(null),
    },
    {
      title: "suppliers",
      active: false,
      href: "#suppliers",
      ref: useRef<HTMLLIElement>(null),
    },
    {
      title: "contact",
      active: false,
      href: "#contact",
      ref: useRef<HTMLLIElement>(null),
    },
  ]);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const changeActiveNavItem = (title: string) => {
    const newNavItems = navItems.map((item) => {
      item.active = item.title === title;
      return item;
    });

    setNavItems(newNavItems);
  };

  const showModalMenu = () => {
    setIsShowModal(true);
    document.body.style.overflow = "hidden";
  };

  const hideModalMenu = () => {
    setIsShowModal(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div className={isShowModal ? styles.showModal : ""}>
      <TopBar />
      <Header
        showModalMenu={showModalMenu}
        navItems={navItems}
        changeActiveNavItem={changeActiveNavItem}
      />
      {children}
      <Footer />
      {isShowModal && (
        <Modal
          hideModalMenu={hideModalMenu}
          navItems={navItems}
          changeActiveNavItem={changeActiveNavItem}
        />
      )}
    </div>
  );
};

export default MainLayout;
