import React, { FC } from "react";
import Section from "../../common/ui/base/section";
import SectionTitle from "../../common/ui/layout/content-layout/SectionTitle";
import AsufixDel from "./asufix-del/AsufixDel";
import AsufixMicro from "./asufix-micro/AsufixMicro";
import CompoundSamba from "./compound-samba/CompoundSamba";
import EcofinishHL from "./Ecofinish-hl/EcofinishHL";

import styles from "./Innovations.module.scss";
interface Props {
  bg?: boolean;
}

const Innovations: FC<Props> = (props: Props) => {
  const { bg } = props;

  return (
    <Section id="innovations" className={`${styles.innovations} m-0`} bg={bg}>
      <SectionTitle
        section="innovations"
        title="innovations"
        subTitle="Explore our"
        desc=""
      />

      <AsufixMicro />
      <CompoundSamba />
      <AsufixDel />
      <EcofinishHL />
    </Section>
  );
};

export default Innovations;
