import React, { FC, ReactElement } from "react";

import {
  Button,
  MobileStepper,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  StyledEngineProvider,
  useTheme,
} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import "./CStepper.scss";
import useWindowSize from "../../../utils/hooks/useWindowSize";

interface Props {
  steps: Array<{ label: string; icon: ReactElement }>;
  activeStep: number;
  setActiveStep: (activeStep: number) => void;
}

const CStepper: FC<Props> = (props: Props) => {
  const { steps, activeStep = 0, setActiveStep } = props;

  const theme = useTheme();
  const { width = 1000 } = useWindowSize();
  const isMobile = width < 768;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <StyledEngineProvider injectFirst>
      {isMobile ? (
        <MobileStepper
          variant="text"
          steps={steps.length}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === steps.length - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      ) : (
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((step, idx) => (
            <Step
              key={step.label}
              onClick={() => setActiveStep(idx)}
              sx={{ cursor: "pointer", textTransform: "capitalize" }}
            >
              <StepLabel
                StepIconComponent={(props: StepIconProps) => {
                  const { active, completed, className } = props;

                  return (
                    <div
                      className={`${className} ${active && "active"} ${
                        completed && "completed"
                      }`}
                    >
                      {step.icon}
                    </div>
                  );
                }}
              >
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </StyledEngineProvider>
  );
};

export default CStepper;
