import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { scrollToID } from "../../../../utils/helpers/scrollToID";
import { INNOVATIONS, MARKETS, SERVICES } from "../constants";

import styles from "./Footer.module.scss";
import { EMAIL } from "../../../../utils/constants";

const Footer = () => {
  return (
    <footer id={styles.footer}>
      <div className={styles["footer-top"]}>
        <Container>
          <Row>
            <Col lg={3} xs={6} className={styles["footer-contact"]}>
              <h4 className={styles.logo}>
                Chemzone<span>.</span>
              </h4>
              <p>
                9/2 Ly Van Phuc Street <br />
                Tan Dinh Ward, District 1
                <br />
                HCMC, Vietnam <br />
                <br />
                <strong>Phone:</strong> +84 (028) 668 66698
                <br />
                <strong>Email:</strong> {EMAIL}
                <br />
              </p>
            </Col>

            <Col lg={3} xs={6} className={styles["footer-links"]}>
              <h4>Markets</h4>
              <ul>
                {MARKETS.map((link, idx) => (
                  <li key={idx} className="text-capitalize">
                    <i className="fa-solid fa-chevron-right"></i>{" "}
                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToID("#markets");
                      }}
                    >
                      {link}
                    </p>
                  </li>
                ))}
              </ul>
            </Col>

            <Col lg={3} xs={6} className={styles["footer-links"]}>
              <h4>Chemical Innovations</h4>
              <ul>
                {INNOVATIONS.map((link, idx) => (
                  <li key={idx}>
                    <i className="fa-solid fa-chevron-right"></i>{" "}
                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToID(`#${link.replaceAll(" ", "-")}`);
                      }}
                    >
                      {link}
                    </p>
                  </li>
                ))}
              </ul>
            </Col>

            <Col lg={3} xs={6} className={styles["footer-links"]}>
              <h4>Services</h4>
              <ul>
                {SERVICES.map((service, idx) => (
                  <li key={idx}>
                    <i className="fa-solid fa-chevron-right"></i>{" "}
                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToID("#services");
                      }}
                    >
                      {service}
                    </p>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <div className={styles.copyright}>
            &copy; Copyright <strong>Chemzone</strong>. All Rights Reserved
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
