import React, { FC, useState } from "react";
import { scrollToID } from "../../../utils/helpers/scrollToID";
import { NavItem } from "../../layout/main-layout/model";

import styles from "./CDropdown.module.scss";

interface Props {
  item: NavItem;
  CStyle?: string;
}

const CDropdown: FC<Props> = (props: Props) => {
  const { item, CStyle } = props;
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);

  return (
    <div
      className={`${styles.dropdown} ${CStyle}`}
      onMouseEnter={() => setIsShowDropdown(true)}
      onMouseLeave={() => setIsShowDropdown(false)}
    >
      <div className={styles.dropdownToggle}>
        <span>{item.title}</span>
        <i className="fa-solid fa-chevron-down"></i>
      </div>
      <ul className={`${styles.popover} ${isShowDropdown && styles.show}`}>
        {item.subItems &&
          item.subItems.map((subItem) => (
            <li
              key={subItem.title}
              className={styles.subItem}
              onClick={(e) => {
                e.preventDefault();
                if (subItem.href) scrollToID(subItem.href);
              }}
            >
              <a href={subItem.href}>{subItem.title}</a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CDropdown;
