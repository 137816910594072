import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Bluesign, Zero } from "../../../common/ui/assets/images/innovations";

import styles from "../Innovations.module.scss";

const EcofinishHL = () => {
  const ecofinishHLContent: String[] = [
    "ECOFINISH HL have been designed to revolutionate the concept of biopolishing when we talk about dyeing on cationized garments.",
    "Its genuine system provides to save water significantly meanwhile provide to garments an exellent abrasion and biopolish effect, working in a conventional washing machine.",
    "In addition, ECOFINISH HL application does not affect the original shade of garments, respects the tear strength of fabrics, and it is suitable for ASUDEL, ASUFIX DEL, COOL PIGMENT and SMART PIGMENTARY processes.",
    "Ecofinish HL is compliant with the main certifications such as:",
  ];
  const EcofinishLogos = [Bluesign, Zero];

  return (
    <Container
      className={`${styles.container} px-3 px-sm-0`}
      data-aos="zoom-in-down"
      id="Ecofinish-HL"
    >
      <Row className="mx-0 pt-4">
        <Col className={`d-flex flex-column ${styles.title__info}`}>
          <h1 className={styles.title}>Ecofinish HL</h1>
        </Col>
      </Row>

      <Row
        className={`flex-column px-0 ${styles.content}`}
        data-aos="fade-down"
      >
        {ecofinishHLContent.map((content, idx) => (
          <p className="mb-5" key={idx}>
            {content}
          </p>
        ))}

        <div
          className={`d-flex flex-wrap align-items-center py-3 gap-3 ${styles.logo}`}
        >
          {EcofinishLogos.map((logo, idx) => (
            <Col
              className="d-flex align-items-center justify-content-center"
              key={idx}
            >
              <Image src={logo} fluid />
            </Col>
          ))}
        </div>
      </Row>
    </Container>
  );
};

export default EcofinishHL;
