import { useEffect, useState } from "react";

export const useGetOffsetTop = () => {
  const [offsetTop, setOffsetTop] = useState<number>(0);

  useEffect(() => {
    const onScroll = () => setOffsetTop(window.pageYOffset);
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return { offsetTop };
};
