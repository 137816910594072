import React, { FC } from "react";
import { createPortal } from "react-dom";
import { NavItem } from "../../layout/main-layout/model";
import { AnimatePresence, motion } from "framer-motion";

import styles from "./Modal.module.scss";
import { scrollToID } from "../../../utils/helpers/scrollToID";
import CAccordion from "../CAccordion";

interface Props {
  navItems: Array<NavItem>;
  changeActiveNavItem: (title: string) => void;
  hideModalMenu: () => void;
}

const Modal: FC<Props> = (props: Props) => {
  const { navItems, hideModalMenu, changeActiveNavItem } = props;

  const modalRoot = document.getElementById("modal");

  return (
    <>
      {modalRoot ? (
        createPortal(
          <AnimatePresence>
            <motion.div
              className={styles.wrapper}
              style={{ left: `50%`, top: `${window.pageYOffset}px` }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.3 } }}
            >
              <i className="fa-solid fa-xmark" onClick={hideModalMenu}></i>

              <div
                className={styles.modal}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ul>
                  {navItems.map((item, idx) => (
                    <>
                      {item.subItems ? (
                        <li key={idx} className={styles.navItem}>
                          <CAccordion
                            item={item}
                            hideModalMenu={hideModalMenu}
                          />
                        </li>
                      ) : (
                        <li
                          key={idx}
                          className={`${styles.navItem} ${
                            item.active ? styles.active : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            changeActiveNavItem(item.title);
                            hideModalMenu();

                            if (item.href) scrollToID(item.href);
                          }}
                        >
                          <a href={item.href} className={styles.navItem_link}>
                            {item.title}
                          </a>
                        </li>
                      )}
                    </>
                  ))}
                </ul>
              </div>
            </motion.div>
          </AnimatePresence>,
          modalRoot
        )
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Modal;
