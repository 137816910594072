import React, { FC, ReactElement, useState } from "react";

import { TabContext } from "@mui/lab";
import { Box, Tab, Tabs } from "@mui/material";
import useWindowSize from "../../../utils/hooks/useWindowSize";
import { TabPanel } from "@mui/lab";
import { MarketTabs } from "../../../utils/i18n/enum";
import { Container } from "react-bootstrap";

interface Props {
  tabs: Array<{ label: MarketTabs | string; component: ReactElement }>;
  fluid?: boolean;
  default?: MarketTabs | undefined;
}

const CTabContext: FC<Props> = (props: Props) => {
  const { tabs, fluid = false } = props;

  const [value, setValue] = useState<string>(tabs[0].label);
  const { width = 1000 } = useWindowSize();
  const isTablet = width < 520;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Container fluid={fluid} className="p-0">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: 8,
          }}
        >
          <Tabs
            onChange={handleChange}
            value={value}
            centered={!isTablet}
            variant={isTablet ? "scrollable" : "fullWidth"}
          >
            {tabs.map((tab, idx) => (
              <Tab label={tab.label} value={tab.label} key={idx} />
            ))}
          </Tabs>
        </Box>
      </Container>
      {tabs.map((tab, idx) => (
        <TabPanel value={tab.label} key={idx} className="p-0">
          {tab.component}
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default CTabContext;
