import React, { FC } from "react";

import { Container } from "react-bootstrap";
import Section from "../../common/ui/base/section";
import SectionTitle from "../../common/ui/layout/content-layout/SectionTitle";

import styles from "./Markets.module.scss";
import { Box } from "@mui/material";
import Textile from "./items/textile";
import { MarketTabItem } from "../model";
import CTabContext from "../../common/ui/base/CTabContext";
import HomeCare from "./items/HomeCare";
import Paper from "./items/Paper";
import { MarketTabs } from "../../common/utils/i18n/enum";
import Rubber from "./items/Rubber";
import ComingSoon from "../../common/ui/base/ComingSoon";

interface Props {
  bg?: boolean;
}

const Markets: FC<Props> = (props: Props) => {
  const { bg } = props;

  const tabs: Array<MarketTabItem> = [
    { label: MarketTabs.TEXTILE, component: <Textile /> },
    { label: MarketTabs.PAPER, component: <ComingSoon /> },
    { label: MarketTabs.HOMECARE, component: <ComingSoon /> },
    { label: MarketTabs.RUBBER, component: <ComingSoon /> },
  ];

  return (
    <Section id="markets" className={`${styles.markets} m-0`} bg={bg}>
      <Container data-aos="fade-up" fluid className="px-3 px-sm-0">
        <SectionTitle
          title="Markets"
          subTitle="Check our"
          section="Markets"
          desc=""
        />

        <Box
          sx={{
            width: "100%",
          }}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <CTabContext tabs={tabs} />
        </Box>
      </Container>
    </Section>
  );
};

export default Markets;
