export enum ScopeKey {
  LANG = "locale",
}

export enum ScopeValue {
  VIE = "vn",
  ENG = "en",
  JPN = "kr",
  CHN = "cn",
}

export interface localeItem {
  locale: string;
  code: string;
  isActive: boolean;
}

export enum MarketTabs {
  TEXTILE = "Textile",
  HOMECARE = "Personal & Home Care",
  PAPER = "Paper",
  WATER_TREATMENT = "Water Treatment",
  COATING = "Coating",
  PLASTICS = "Plastics",
  RUBBER = "Rubber",
  FOOD = "Food",
}
