export const ADDRESS =
  "9/2 Ly Van Phuc Street, Tan Dinh Ward, District 1, HCMC. VN";

export const BREAK_POINTS_MASONRY = {
  default: 3,
  992: 2,
  768: 1,
};

export const EMAIL = "info@chemzonevn.com";

export const PHONE_NUMBER = "+84 (028) 668 66698";
