import React from "react";
import { Container } from "react-bootstrap";
import { EMAIL, PHONE_NUMBER } from "../../../../utils/constants";

import { ReactComponent as Mail } from "../../../assets/images/ic/mail.svg";
import { ReactComponent as Phone } from "../../../assets/images/ic/phone.svg";

import styles from "./TopBar.module.scss";

const TopBar = () => {
  return (
    <div className={styles.topbar}>
      <Container className={styles.wrapper}>
        <div className={styles.contact}>
          <span>
            <Mail />
            <a href="mailto:contact@example.com">{EMAIL}</a>
          </span>
          <span>
            <Phone />
            {PHONE_NUMBER}
          </span>
        </div>
      </Container>
    </div>
  );
};

export default TopBar;
