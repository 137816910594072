import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
  AsufixDel1,
  AsufixDel2,
} from "../../../common/ui/assets/images/innovations";

import styles from "../Innovations.module.scss";

const AsufixDel = () => {
  return (
    <Container
      className={`${styles.container} px-3 px-sm-0`}
      data-aos="fade-up"
      id="Asufix-DEL"
    >
      <Row className="mx-0 pt-4">
        <Col lg={6} className={`d-flex flex-column ${styles.title__info}`}>
          <h1 className={styles.title}>Asufix DEL</h1>
        </Col>
      </Row>
      <Row className={styles.asufixDel}>
        <Col lg={6}>
          <Image src={AsufixDel1} alt="" fluid />
          <p>
            Asufix DEL is our dischargeable reactive dyestuff range. It can be
            dyed directly on cotton or on cationized fibres depending on the
            desired effect and properties.
          </p>
        </Col>
        <Col lg={6}>
          <Image src={AsufixDel2} alt="" fluid />
          <p>
            With one single range, we can cover a big variety of fashion
            requirements. No matter if it is on woven or knitted fabrics, with
            ASUFIX DEL we can fulfill the strictest demands
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AsufixDel;
