import React, { FC } from "react";

import { Col, Container, Row } from "react-bootstrap";
import Section from "../../common/ui/base/section";
import SectionTitle from "../../common/ui/layout/content-layout/SectionTitle";
import { ADDRESS, EMAIL } from "../../common/utils/constants";

import styles from "./Contact.module.scss";
interface Props {
  bg?: boolean;
}

const Contact: FC<Props> = (props: Props) => {
  const { bg } = props;

  return (
    <Section id="contact" className={styles.contact} bg={bg}>
      <Container data-aos="fade-up">
        <SectionTitle title="Contact" subTitle="" section="" desc="" />

        <Row data-aos="fade-up" data-aos-delay="100">
          <Col lg={6} className="mb-lg-0 mb-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.259021964792!2d106.6933088!3d10.7914628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752997f3bbc227%3A0x5fbaf85df97dc8dc!2sCHEMZONE%20Co.%20Ltd.%2C!5e0!3m2!1sen!2s!4v1660967015280!5m2!1sen!2s"
              style={{ border: "0", width: "100%", height: "100%" }}
              title="map"
              allowFullScreen={true}
            />
          </Col>
          <Col lg={6} className="justify-content-between flex-column">
            <div className={`${styles["info-box"]} mb-4`}>
              <i className="fa-solid fa-map"></i>
              <h3>Our Address</h3>
              <p>{ADDRESS}</p>
            </div>

            <div className={styles["info-box"]}>
              <i className="fa-solid fa-envelope"></i>
              <h3>Email Us</h3>
              <p>{EMAIL}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default Contact;
