import React, { FC, Fragment, ReactNode, useEffect } from "react";
import { Container } from "react-bootstrap";
import { scrollToID } from "../../../../utils/helpers/scrollToID";
import { useGetOffsetTop } from "../../../../utils/hooks/useGetOffsetTop";
import { NavItem } from "../model";

import styles from "./Header.module.scss";
import CDropdown from "../../../base/CDropdown";
import { logo } from "../../../assets/images";

interface Props {
  children?: ReactNode;
  navItems: Array<NavItem>;
  changeActiveNavItem: (title: string) => void;
  showModalMenu: () => void;
}

const Header: FC<Props> = (props: Props) => {
  const { navItems, changeActiveNavItem, showModalMenu } = props;

  const { offsetTop } = useGetOffsetTop();

  const navbarLinksActive = () => {
    const position = window.scrollY + 200;
    navItems.forEach((item) => {
      if (item.ref && item.ref.current) {
        const anchor = item.ref.current.childNodes[0] as HTMLElement;
        if (anchor) {
          const target = anchor.getAttribute("href");
          if (target) {
            const section = document.querySelector(target) as HTMLElement;
            if (
              section &&
              position >= section.offsetTop &&
              position <= section.offsetTop + section.offsetHeight
            ) {
              changeActiveNavItem(item.title);
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", navbarLinksActive);

    return () => window.removeEventListener("scroll", navbarLinksActive);
  });

  return (
    <header
      id="header"
      className={`${styles.header} ${
        offsetTop > 40 ? styles["fixed-top"] : ""
      }`}
    >
      <Container className={styles.wrapper}>
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>
        <nav className={styles.navbar}>
          <ul>
            {navItems.map((item) => (
              <Fragment key={item.title}>
                {item.subItems ? (
                  <li
                    key={item.title}
                    className={styles.mainItem}
                    ref={item.ref}
                  >
                    <CDropdown item={item} />
                  </li>
                ) : (
                  <li
                    key={item.title}
                    ref={item.ref}
                    className={`${styles.mainItem} ${
                      item.active ? styles.active : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      changeActiveNavItem(item.title);

                      if (item.href) scrollToID(item.href);
                    }}
                  >
                    <a href={item.href} className={styles.mainItem_link}>
                      {item.title}
                    </a>
                  </li>
                )}
              </Fragment>
            ))}
          </ul>
        </nav>
        <i
          className="fa-solid fa-bars d-block d-lg-none"
          onClick={showModalMenu}
        ></i>
      </Container>
    </header>
  );
};

export default Header;
