import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
  Bluesign,
  EIM,
  Zero,
} from "../../../common/ui/assets/images/innovations";

import styles from "../Innovations.module.scss";

const AsufixMicro = () => {
  const asufixMicroContent: String[] = [
    "Asufix Micro is the first reactive dyeing range and process to dye by nebulization systems.",
    "The process is carried out without water or temperature.",
    "For the first time, reactive dyeing can be applied without glaubert salt, which turns wastewater into reusable water, resulting in a completely eco-friendly dyeing system.",
    "The result in terms of equalization is a solid look with excellent fastnesses.",
    "In addition, Asufix Micro can be applied on mordanted goods as well, providing a worn-out effect on garments.",
    "Asufix Micro is compliant with the main certifications.",
  ];
  const asufixMicroLogos = [Bluesign, EIM, Zero];

  return (
    <Container
      className={`${styles.container} px-3 px-sm-0`}
      data-aos="zoom-in-down"
      id="Asufix-Micro"
    >
      <Row className="mx-0 mb-lg-5 mb-md-3 mb-0 pt-4">
        <Col
          lg={6}
          className={`d-flex flex-column align-items-center text-center ${styles.title__info} ${styles.asufixMicro}`}
        >
          <h1 className={styles.title}>Asufix Micro</h1>

          <p
            className={`${styles.subTitle} d-none d-lg-block px-5`}
            data-aos="fade-down"
            data-aos-easing="ease-in-sine"
          >
            Reactive Dyestuff Range For Nebulization Systems Finished By
            Water-Free Biopolish Enzyme
          </p>
        </Col>
        <Col
          lg={6}
          className={`d-flex justify-content-lg-end justify-content-center ${styles.video}`}
        >
          <video autoPlay muted loop id="myVideo" className="w-100">
            <source
              src="https://res.cloudinary.com/dcsi3yllr/video/upload/v1660448943/chemzone/videos/asufix-micro.mp4"
              type="video/mp4"
            />
          </video>
        </Col>
      </Row>

      <Row
        className={`flex-column px-0 ${styles.content}`}
        data-aos="fade-down"
        data-aos-delay="200"
      >
        {asufixMicroContent.map((content, idx) => (
          <p className="mb-5" key={idx}>
            {content}
          </p>
        ))}

        <div
          className={`d-flex flex-wrap align-items-center py-3 gap-3 ${styles.logo}`}
        >
          {asufixMicroLogos.map((logo, idx) => (
            <Col
              className="d-flex align-items-center justify-content-center"
              key={idx}
            >
              <Image src={logo} fluid />
            </Col>
          ))}
        </div>
      </Row>
    </Container>
  );
};

export default AsufixMicro;
