import React, { FC, Fragment, useEffect, useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import styles from "./Textile.module.scss";
import { ExpandInfo, Product } from "../../../model";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import ProductExpand from "./ProductExpand";

interface Props {
  product: Product;
}

const ProductBox: FC<Props> = (props: Props) => {
  const { product } = props;
  const { title, description, expand_info } = product;

  const [productData, setProductData] = useState<Array<ExpandInfo>>([]);
  const [isExpandContent, setIsExpandContent] = useState<boolean>(false);

  const handleClick = (text: string) => {
    if (productData) {
      const newProductData = productData.map((item) => {
        if (item.label && item.label.toLowerCase() === text.toLowerCase()) {
          item.isOpen = !item.isOpen;
        }

        return item;
      });

      setProductData(newProductData);
    }
  };

  useEffect(() => {
    setProductData(expand_info || []);
  }, [setProductData, expand_info]);

  return (
    <div className={`${styles["product-box"]}`}>
      <h4>
        <span>{title}</span>
      </h4>
      <p>{description}</p>
      <Collapse in={isExpandContent} timeout="auto" unmountOnExit>
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {expand_info?.map((info, idx) => {
            return !info.label ? (
              <Fragment key={idx}>
                {info.data.map((data) => (
                  <ListItemButton key={data.text}>
                    <ListItemText
                      primary={
                        <span>
                          {data.text}
                          <sub>{data.sub}</sub>
                        </span>
                      }
                      sx={{ textTransform: "capitalize" }}
                    />
                  </ListItemButton>
                ))}
              </Fragment>
            ) : (
              <div key={idx}>
                <ListItemButton
                  onClick={() => {
                    if (info.label) handleClick(info.label);
                  }}
                >
                  <ListItemText
                    primary={info.label}
                    sx={{ textTransform: "capitalize" }}
                  />
                  {info.isOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <ProductExpand info={info} />
              </div>
            );
          })}
        </List>
      </Collapse>
      {expand_info && (
        <div
          className={styles.expandArrow}
          onClick={() => setIsExpandContent(!isExpandContent)}
        >
          {isExpandContent ? <ExpandLess /> : <ExpandMore />}
        </div>
      )}
    </div>
  );
};

export default ProductBox;
