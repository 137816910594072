import React, { FC, useState } from "react";
import { Container, Image } from "react-bootstrap";

import Section from "../../common/ui/base/section";
import SectionTitle from "../../common/ui/layout/content-layout/SectionTitle";
import supplierImgs from "../../common/ui/assets/images/suppliers";
import { Supplier } from "../model";

import styles from "./Suppliers.module.scss";

interface Props {
  bg?: boolean;
}

const Suppliers: FC<Props> = (props: Props) => {
  const { bg } = props;

  const { asutex, yorkshire, elkem, rudolf } = supplierImgs;
  const [suppliers] = useState<Array<Supplier>>([
    { logo: asutex },
    { logo: yorkshire },
    { logo: elkem },
    { logo: rudolf },
  ]);

  return (
    <Section id="suppliers" className={styles.supplier} bg={bg}>
      <Container data-aos="zoom-in">
        <SectionTitle
          title="suppliers"
          subTitle="Check our"
          section="suppliers"
          desc=""
        />

        <div className={styles.wrapper}>
          {suppliers.map((supplier, idx) => (
            <div className={styles["supplier-box"]} key={idx}>
              <Image fluid src={supplier.logo} alt="supplier" />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default Suppliers;
