import React, { useState } from "react";

import { Box } from "@mui/material";
import ColorizeIcon from "@mui/icons-material/Colorize";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DryCleaningIcon from "@mui/icons-material/DryCleaning";
import CStepper from "../../../../common/ui/base/CStepper";
import { ReactComponent as Auxiliary } from "../../../../common/ui/assets/images/ic/auxiliary-cable.svg";

import styles from "./Textile.module.scss";
import Masonry from "react-masonry-css";
import { BREAK_POINTS_MASONRY } from "../../../../common/utils/constants";
import ProductBox from "./ProductBox";
import { auxilary, dyeStuff, finishing, laundry } from "./model";
import { Col, Container, Image } from "react-bootstrap";
import {
  Bluesign,
  EIM,
  GOTS,
  OEKO,
  ZDHC,
} from "../../../../common/ui/assets/images/innovations";

const Textile = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const certificationsLogo = [Bluesign, ZDHC, GOTS, OEKO, EIM];
  const steps = [
    {
      label: "Auxiliary",
      icon: <Auxiliary />,
      content: auxilary,
    },
    {
      label: "Dyestuff",
      icon: <ColorizeIcon />,
      content: dyeStuff,
    },

    {
      label: "finishing & coating",
      icon: <DoneAllIcon />,
      content: finishing,
    },
    { label: "laundry", icon: <DryCleaningIcon />, content: laundry },
  ];

  const progresses = steps.map((step, idx) => (
    <Masonry
      breakpointCols={BREAK_POINTS_MASONRY}
      className={styles.masonry}
      key={idx}
    >
      {step.content.map((product, i) => (
        <div className="pb-3" key={i} data-aos="zoom-in">
          <ProductBox product={product} />
        </div>
      ))}
    </Masonry>
  ));

  return (
    <Box sx={{ width: "100%" }} className={styles.textile}>
      <Container fluid className={`${styles.container} mt-2 mt-lg-4`}>
        <h1 className="text-uppercase user-select-none">Textile</h1>
      </Container>

      <Container className="mt-5 p-0">
        <Box sx={{ width: "100%" }}>
          <CStepper
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </Box>

        <div data-aos="zoom-in" data-aos-delay="100">
          <h2 className="text-center mt-5 mb-4 fs-1 fw-bold text-capitalize">
            {steps[activeStep].label}
          </h2>
          {progresses[activeStep]}
        </div>

        <div
          className={styles.certifications}
          data-aos="zoom-in-up"
          data-aos-delay="200"
        >
          <div className={styles.title}>
            <h2>Certifications</h2>
          </div>
          <div className={styles.desc}>
            <p>
              We never stop working with supplier for sustainability. Browse our
              certified products list.
            </p>
            <p>Please contact us for more details</p>
          </div>
          <div
            className={`d-flex flex-wrap align-items-center justify-content-center py-3 ${styles.logo}`}
          >
            {certificationsLogo.map((logo, idx) => (
              <Col
                lg={4}
                md={6}
                className="d-flex align-items-center justify-content-center px-3 py-lg-0 py-4"
                key={idx}
              >
                <Image src={logo} fluid alt={logo} />
              </Col>
            ))}
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default Textile;
