import React, { FC } from "react";

import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "../../common/ui/layout/content-layout/SectionTitle";
import Section from "../../common/ui/base/section";

import styles from "./About.module.scss";
import { about } from "../../common/ui/assets/images";

interface Props {
  bg?: boolean;
}

const About: FC<Props> = (props: Props) => {
  const { bg } = props;

  return (
    <Section id="about" className={styles.about} bg={bg}>
      <Container data-aos="fade-up" className="px-3 px-sm-0">
        <SectionTitle
          title="About"
          subTitle="Find out more"
          section="About Us"
          desc=""
        />

        <Row>
          <Col lg={6} data-aos="fade-right" data-aos-delay="200">
            <img src={about} className="img-fluid" alt="about-us" />
          </Col>
          <Col
            lg={6}
            className={`pt-4 pt-lg-0 d-flex flex-column ${styles.content}`}
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <p>
              CHEMZONE is one of the leading and distributor of specialty
              chemicals and ingredients in Vietnam.
            </p>
            <p>
              A new company is set up in 2021, Jan with an experienced team, has
              been in both production and chemicals distribution business more
              than 30 years. Our technical staff offer not only practical
              expertise, but also international business experience and strong
              knowledge of local markets and cultures.
            </p>
            <p>
              Our main market is Textile, Paper, Personal and Home Care and
              Rubber.
            </p>
            <p>
              A full set up to combine a good knowledge in chemicals application
              plus technical lab and blending facility which we are confident to
              provide solution to customers as well as elaborate tailor-made for
              our partnerships.
            </p>
            <p>
              The best logistic service to make us becomes first choice of
              customers.
            </p>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default About;
