export const MARKETS = ["textile", "Paper", "personal & home care", "rubber"];
export const INNOVATIONS = [
  "Asufix Micro",
  "Compound Samba PES",
  "Asufix DEL",
  "Asucel WF",
  "Ecofinish HL",
];
export const SERVICES = [
  "Warehouse & logistic",
  "Blending Facility",
  "Laboratory",
];
