import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import Section from "../../common/ui/base/section";
import { useGetOffsetTop } from "../../common/utils/hooks/useGetOffsetTop";

import styles from "./Hero.module.scss";

const Hero = () => {
  const { offsetTop } = useGetOffsetTop();

  return (
    <Section
      id="hero"
      className={`${offsetTop > 40 ? styles["scrolled-offset"] : ""} ${
        styles.hero
      } d-flex`}
    >
      <Container
        data-aos="zoom-out"
        data-aos-delay="200"
        data-aos-easing="linear"
      >
        <Row>
          <Col xs={12}>
            <h1>
              Welcome to <span>Chemzone</span>
            </h1>
          </Col>
          <Col xs={12} lg={6}>
            <h2 className={`${styles.hero__desc} text-capitalize mt-2`}>
              A chemical solution provider with great technical team and best
              logistic service
            </h2>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default Hero;
