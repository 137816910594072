import { Product } from "../../../model";

export const auxilary: Array<Product> = [
  {
    title: "Pretreatment",
    description: "",
    expand_info: [
      {
        data: [
          { text: "wetting" },
          { text: "sequestering" },
          { text: "stabilizer" },
          { text: "sequestering" },
          { text: "Antifoam" },
          { text: "Lubricant" },
          { text: "Anticrease" },
        ],
      },
      {
        label: "Enzyme",
        data: [
          { text: "BioScouring" },
          { text: "Desizing" },
          { text: "BioPolishing" },
          { text: "Catalase" },
          { text: "Paper" },
        ],
        isOpen: false,
      },
    ],
  },
  {
    title: "Aftertreatment",
    description: "",
  },
  {
    title: "Dyebath",
    description: "",
    expand_info: [
      {
        data: [
          { text: "Leveling" },
          { text: "Dispersing" },
          { text: "Buffer" },
        ],
      },
    ],
  },
];

export const dyeStuff: Array<Product> = [
  {
    title: "Disperse Dyes",
    description: "",
    expand_info: [
      {
        data: [
          { text: "Normal / Economics" },
          { text: "Bright / Luminous" },
          { text: "High lightfastness" },
          { text: "High wetfastness" },
        ],
      },
    ],
  },
  {
    title: "Acid Dyes",
    description: "",
    expand_info: [
      {
        data: [
          { text: "Luminous Dye" },
          { text: "B Group" },
          { text: "C Group" },
        ],
      },
    ],
  },
  {
    title: "Metal Complex",
    description: "",
    expand_info: [
      {
        data: [{ text: "B Group" }, { text: "C Group" }],
      },
    ],
  },
  {
    title: "Reactive Dyes",
    description: "",
    expand_info: [
      {
        data: [
          { text: "Normal" },
          { text: "Economic" },
          { text: "High lightfastness / Perpiration fastness" },
        ],
      },
    ],
  },
];

export const finishing: Array<Product> = [
  {
    title: "Softeners",
    description: "",
    expand_info: [
      {
        label: "Micro Silicone",
        data: [
          { text: "Dark Shading" },
          { text: "White Pale / Bright Shading" },
        ],
        isOpen: false,
      },
      {
        label: "Macro Silicone",
        data: [],
        isOpen: false,
      },
      {
        data: [
          { text: "Hydrophilic Silicone" },
          { text: "Fatty Acid / Amine" },
          { text: "Polyurethane" },
        ],
      },
    ],
  },
  {
    title: "Flame Retardant",
    description: "",
    expand_info: [
      {
        data: [{ text: "Coating" }, { text: "Padding" }],
      },
    ],
  },
  {
    title: "Water Repellant",
    description: "",
    expand_info: [
      {
        data: [
          { text: "C", sub: "0" },
          { text: "C", sub: "6" },
          { text: "Silicone" },
        ],
      },
    ],
  },
  {
    title: "UV Absorbers",
    description: "",
    expand_info: [
      {
        data: [
          { text: "Polyesther" },
          { text: "Polyamid" },
          { text: "Cotton" },
        ],
      },
    ],
  },
  {
    title: "Antibacteria / Antifungi",
    description: "",
  },
];

export const laundry: Array<Product> = [
  {
    title: "Special Dyes",
    description: "",
    expand_info: [
      {
        data: [
          { text: "Asudel Dyes" },
          { text: "Asufix DEL Dyes" },
          { text: "Sulfur Dyes" },
          { text: "Pigment Dyes" },
          { text: "Direct Dyes" },
        ],
      },
    ],
  },
];
