import React, { FC } from "react";

import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import { ExpandInfo } from "../../../model";

interface Props {
  info: ExpandInfo;
}

const ProductExpand: FC<Props> = (props: Props) => {
  const { info } = props;

  return (
    <Collapse in={info.isOpen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {info.data.map((data, i) => (
          <ListItemButton sx={{ pl: 4, maxWidth: "auto" }} key={i}>
            <ListItemText
              primary={
                <span>
                  {data.text}
                  <sub>{data.sub}</sub>
                </span>
              }
              sx={{ textTransform: "capitalize" }}
            />
          </ListItemButton>
        ))}
      </List>
    </Collapse>
  );
};

export default ProductExpand;
