import React from "react";

import styles from "./ComingSoon.module.scss";

const ComingSoon = () => {
  return (
    <div className={styles.container}>
      <h4>Coming Soon</h4>
      <div className={styles.stage}>
        <div className={styles["dot-flashing"]}></div>
      </div>
    </div>
  );
};

export default ComingSoon;
