import React, { FC, ReactNode } from "react";
import styles from "./Section.module.scss";

interface Props {
  children: ReactNode;
  id?: string;
  className?: string;
  bg?: boolean;
}

const Section: FC<Props> = (props: Props) => {
  const { children, bg = false, id, className = "" } = props;

  return (
    <section
      id={id}
      className={`${styles.section} ${
        bg ? styles["section-bg"] : ""
      } ${className}`}
    >
      {children}
    </section>
  );
};

export default Section;
